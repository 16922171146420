import { Link } from "gatsby"
import React from "react"
import styled from "styled-components/macro"

import { useHeroAssets } from "../../../hooks/hero"
import { pxToRem } from "../../../utils"

const HeroWrapper = styled.section`
  height: calc(100vh - 85px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-image: ${props => (props.bg ? `url(${props.bg})` : "")};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media all and (min-width: 1025px) {
    flex-direction: row;
  }
`
// const LeftTriangle = styled.div`
//   display: none;
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 100;
//   clip-path: polygon(0% 0, 0 100%, 100% 0);
//   width: 14%;
//   height: calc(100vh - 85px);
//   background: linear-gradient(
//     0deg,
//     rgba(91, 64, 48, 1) 0%,
//     rgba(184, 136, 71, 1) 53.54%,
//     rgba(255, 208, 114, 1) 81.75%,
//     rgba(255, 230, 152, 1) 100%
//   );
//   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5B4030',endColorstr='#FFE698' , GradientType=1);

//   @media all and (min-width: 1025px) {
//     display: block;
//     width: 16%;
//   }
//   @media all and (min-width: 1181px) {
//     width: 14%;
//   }
//   @media all and (min-width: 1281px) {
//     width: 16%;
//   }
//   @media all and (min-width: 1681px) {
//     width: 14%;
//   }
// `
// const RightTriangle = styled.div`
//   display: none;
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   z-index: 100;
//   clip-path: polygon(100% 0, 0 100%, 100% 100%);
//   width: 14%;
//   height: calc(100vh - 85px);
//   background: linear-gradient(
//     180deg,
//     rgba(91, 64, 48, 1) 0%,
//     rgba(184, 136, 71, 1) 53.54%,
//     rgba(255, 208, 114, 1) 81.75%,
//     rgba(255, 230, 152, 1) 100%
//   );
//   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5B4030',endColorstr='#FFE698' , GradientType=1);

//   @media all and (min-width: 1025px) {
//     display: block;
//     width: 16%;
//   }
//   @media all and (min-width: 1181px) {
//     width: 14%;
//   }
//   @media all and (min-width: 1281px) {
//     width: 16%;
//   }
//   @media all and (min-width: 1681px) {
//     width: 14%;
//   }
// `
const ImageTitle = styled.h2`
  font-size: ${pxToRem(56)};
  font-weight: 900;
  /* line-height: 0.45; */
  line-height: 0.85;
  color: #fff;
  font-family: "Teko", sans-serif;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  /* height: calc(calc(100vw - 250px) / 3); */

  @media all and (min-width: 1025px) {
    margin-top: ${pxToRem(100)};
    transform: skew(calc(360deg - 344.5deg), 0);
  }
  @media all and (min-width: 1281px) {
    font-size: ${pxToRem(66)};
  }
`
const ImageAlt = styled.h3`
  font-size: ${pxToRem(36)};
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  line-height: 0.85;
  color: #fff;
  margin: 0;
  margin-top: -10px;
  text-transform: uppercase;
  width: 100%;
  padding-right: 0;

  @media all and (min-width: 1025px) {
    max-width: 200px;
    transform: skew(calc(360deg - 344.5deg), 0);
    align-self: flex-end;
  }

  @media all and (min-width: 1681px) {
    max-width: 270px;
  }
  @media all and (min-width: 1921px) {
    max-width: 370px;
  }
`
const OtherImageTitle = styled(ImageTitle)``
const OtherImageAlt = styled(ImageAlt)``
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-shadow: #000 3px 5px 20px;
  /* text-shadow: 0.1em 0.1em 30px hsl(200deg 50% 30%); */
  text-align: center;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-in-out;

  @media all and (min-width: 1025px) {
    text-align: initial;
    justify-content: initial;
  }
`
const ImageContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
  overflow: hidden;
  height: calc(calc(100vh - 85px) / 3);

  &:hover {
    text-decoration: none;
    /* box-shadow: inset 0 0 ${pxToRem(10)} ${props => props.color || "#fff"};
    box-shadow: inset 0px 10px 20px 2px ${props => props.color || "#fff"}; */

    ${ImageTitle} {
      transition: all 0.3s ease-in-out;
      color: ${props => (props.color ? `${props.color}` : `#fff;`)};
    }

    ${ImageAlt} {
      transition: all 0.3s ease-in-out;
      color: ${props => (props.color ? `${props.color}` : `#fff;`)};
    }

    ${OtherImageTitle} {
      transition: all 0.3s ease-in-out;
      color: #fff;
    }

    ${OtherImageAlt} {
      transition: all 0.3s ease-in-out;
      color: #fff;
    }

    ${TitleContainer} {
      box-shadow: inset 0px 0px 5px 2px #fff,
        inset 0px 0px 15px 10px
          ${props => (props.color ? `${props.color}` : `#fff;`)};
    }

    &::before {
      ${props => (props.hover ? `background: url(${props.hover})` : ``)};
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 160%;
    height: 100%;
    top: 0;
    z-index: -10;
    ${props => (props.image ? `background: url(${props.image})` : ``)};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-blend-mode: luminosity; */
    transition: all 0.4s ease-in-out;

    @media all and (min-width: 1025px) and (max-width: 1200px) {
      width: 160%;
    }
  }

  @media all and (min-width: 1025px) {
    width: calc(calc(100vw - 15%) / 3);
    height: initial;
    transform: skew(344.5deg, 0);
    align-items: initial;

    &::before {
      transform: skew(-344.5deg, 0);
    }
  }

  @media all and (min-width: 1281px) {
    width: calc(calc(100vw - 17%) / 3);
  }

  @media all and (min-width: 1681px) {
    width: calc(calc(100vw - 15%) / 3);
  }
`

const Hero = () => {
  const { other, tailor, flipunit, otherBW, tailorBW, flipunitBW, triangleBg } =
    useHeroAssets()
  return (
    <HeroWrapper bg={triangleBg}>
      <ImageContainer
        to="/airtrack-show"
        hover={flipunit}
        image={flipunitBW}
        color="rgba(0, 173, 238, 1)"
      >
        <TitleContainer className="wow animate__fadeIn">
          <ImageTitle>Airtrack</ImageTitle>
          <ImageAlt>Show</ImageAlt>
        </TitleContainer>
      </ImageContainer>
      <ImageContainer
        to="/tailor-made-show"
        hover={tailor}
        image={tailorBW}
        color="#c4ff45"
      >
        <TitleContainer className="wow animate__fadeIn" data-wow-delay=".2s">
          <ImageTitle>Tailor-made</ImageTitle>
          <ImageAlt>Show</ImageAlt>
        </TitleContainer>
      </ImageContainer>
      <ImageContainer
        to="/all-extreme-sport-shows"
        hover={other}
        image={otherBW}
        // color="rgba(0, 173, 238, 1)"
      >
        <TitleContainer className="wow animate__fadeIn" data-wow-delay=".4s">
          <OtherImageTitle>All extreme</OtherImageTitle>
          <OtherImageAlt>Shows</OtherImageAlt>
        </TitleContainer>
      </ImageContainer>
    </HeroWrapper>
  )
}

export default Hero
