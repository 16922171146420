import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/sections/main/Hero"
import Performance from "../components/sections/main/Performance"
import GradientCTA from "../components/sections/main/GradientCTA"
import About from "../components/sections/main/About"
import Acts from "../components/sections/main/Acts"
import Partners from "../components/sections/main/Partners"
import Contact from "../components/sections/main/Contact"

const IndexPage = () => (
  <Layout page="home">
    <Hero />
    <Performance />
    <GradientCTA
      text="Looking for an exceptional performance?"
      buttonText="Contact us now"
      to="/contact"
      skew
    />
    <About />
    <Acts />
    <GradientCTA
      text="Want to see us at your event?"
      buttonText="CONTACT US"
      to="/contact"
      style={{ justifyContent: "space-around" }}
    />
    <Partners />
    <Contact page="home" formName="Contact fix" />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
  <Seo
    title="FLIP UNIT PROD | TOP Extreme Sport Show Entertainment Agency"
    description="We are an extreme sport show production agency and entertainment provider with a wide selection of extreme sport shows with over 120 professional athletes.  
"
  />
)

export default IndexPage
