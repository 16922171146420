import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components/macro"

import LogoIcon from "../../../images/logo-icon.svg"
import Expectation from "./expectation.gif"
import Experts from "./experts.gif"
import Tailored from "./tailored.gif"
import { pxToRem } from "../../../utils"
import Container from "../../common/Container"

const PerformanceWrapper = styled.section`
  /* background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) -1000%,
    rgba(0, 0, 0, 1) 70%
  ); */
  background-color: #000;
  color: #fff;
  padding: ${pxToRem(100)} 0;

  @media all and (min-width: 821px) {
    padding: ${pxToRem(100)} ${pxToRem(30)};
  }
`
const PerformanceContainer = styled(Container)`
  text-align: center;
`
const PerformanceTitle = styled.h2`
  font-weight: 600;
  font-size: ${pxToRem(40)};
  font-family: "Teko", sans-serif;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: ${pxToRem(50)};
`
const PerformanceLogoIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto ${pxToRem(30)} auto;
`
const PerformanceLogoIcon = styled(LogoIcon)`
  color: #fff;
  width: 40px;
  display: block;

  & path {
    fill: #fff;
  }
`
const PerformanceDescription = styled.p`
  font-size: ${pxToRem(28)};
  font-weight: 300;
  font-style: italic;
  margin-bottom: ${pxToRem(100)};
  line-height: 1.3;
`
const PerformanceCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${pxToRem(40)};

  @media all and (min-width: 769px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`
const PerformanceCard = styled.div``
const PerformanceCardTitle = styled.h2`
  margin-top: 0;
  margin-bottom: ${pxToRem(20)};
  text-transform: uppercase;
  font-weight: 300;
  font-size: ${pxToRem(40)};
  font-family: "Teko", sans-serif;
`
const PerformanceCardDescription = styled.p`
  font-size: ${pxToRem(20)};
  font-weight: 300;
  line-height: 1.3;
`

const Performance = () => {
  return (
    <PerformanceWrapper>
      <PerformanceContainer>
        <PerformanceTitle className="wow animate__fadeIn">
          POWERFUL PROFESSIONAL PERFORMANCES <br /> FOR YOUR EVENT
        </PerformanceTitle>
        <PerformanceLogoIconWrapper
          className="wow animate__fadeIn"
          data-wow-delay=".2s"
        >
          <PerformanceLogoIcon />
        </PerformanceLogoIconWrapper>
        <PerformanceDescription
          className="wow animate__fadeIn"
          data-wow-delay=".4s"
        >
          Extreme sports are all about power, dedication and resilience. All
          these qualities could represent <strong>YOUR</strong> brand and
          describe the story of <strong>YOUR</strong> company with a stunning
          show!
        </PerformanceDescription>
        <PerformanceCardContainer>
          <PerformanceCard className="wow animate__fadeIn">
            <img src={Tailored} alt="Tailored to your needs" />
            {/* <StaticImage
              src="../../../images/icon1@3x.png"
              alt="Tailored to your needs"
              width={135}
              style={{ marginBottom: 50 }}
            /> */}
            <PerformanceCardTitle>Tailored to your needs</PerformanceCardTitle>
            <PerformanceCardDescription>
              With years of experience we can provide you with all the
              information you need along the way and with services perfectly
              tailored to your needs and the requirements of your event.
            </PerformanceCardDescription>
          </PerformanceCard>
          <PerformanceCard className="wow animate__fadeIn" data-wow-delay=".2s">
            <img src={Experts} alt="Experts and professionals" />
            {/* <StaticImage
              src="../../../images/icon2@3x.png"
              alt="Experts and professionals"
              height={135}
              style={{ marginBottom: 50 }}
            /> */}
            <PerformanceCardTitle>Experts & professionals</PerformanceCardTitle>
            <PerformanceCardDescription>
              120+ professional athletes with several years of experience and
              expertise who are TOP performers in their own field.
            </PerformanceCardDescription>
          </PerformanceCard>
          <PerformanceCard className="wow animate__fadeIn" data-wow-delay=".4s">
            <img src={Expectation} alt="Expectations = reality" />
            {/* <StaticImage
              src="../../../images/icon3@3x.png"
              alt="Expectations = reality"
              width={135}
              style={{ marginBottom: 50 }}
            /> */}
            <PerformanceCardTitle>Expectations = reality</PerformanceCardTitle>
            <PerformanceCardDescription>
              Everything you see in our videos is true to reality and guaranteed
              to appear the same way at your event.
            </PerformanceCardDescription>
          </PerformanceCard>
        </PerformanceCardContainer>
      </PerformanceContainer>
    </PerformanceWrapper>
  )
}

export default Performance
