import React, { useState, useEffect } from "react"
import styled from "styled-components/macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useSwipeable } from "react-swipeable"

import Container from "../../common/Container"
import LeftArrow from "../../../images/flipunit__icon__left-arrow__white.svg"
import RightArrow from "../../../images/flipunit__icon__right-arrow__white.svg"
import EmptyTriangle from "../../../images/flipunit__icon__bullet-empty__white.svg"
import { useLogos } from "../../../hooks/logos"
import { pxToRem } from "../../../utils"

const Section = styled.section`
  padding: ${pxToRem(75)} 0;
  background-color: #fff;

  @media all and (min-width: 768px) {
    padding: ${pxToRem(100)} 0;
  }
`
const SectionTitle = styled.h2`
  font-weight: 600;
  font-size: ${pxToRem(60)};
  font-family: "Teko", sans-serif;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0;
  margin-bottom: ${pxToRem(80)};
  line-height: 0.9;
  color: #000;
`
const SliderContainer = styled.div`
  position: relative;
  background-color: #fff;
  display: flex;
  overflow: hidden;
  margin-bottom: 2.77777778rem;
  padding: 10px 0;
`
const Image = styled(GatsbyImage)``
const Slide = styled.div`
  display: grid;
  min-width: 100%;
  justify-items: center;
  ${props =>
    props.position ? `transform: translateX(${props.position}%)` : ""};
  transition: transform 0.5s ease;
  will-change: transform;
  grid-template-columns: repeat(2, calc(100% / 2));

  & ${Image} {
    width: 100%;
  }

  @media all and (min-width: 768px) {
    grid-template-columns: repeat(6, calc(100% / 6));
  }
`
const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 15px;
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
    .cls-1 {
      fill: #000;
    }
  }
`
const StyledEmptyTriangle = styled(EmptyTriangle)``
const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  & ${StyledEmptyTriangle} {
    ${props => (props.active ? "transform: rotate(180deg)" : "")};
    transition: transform 0.5s ease;
    will-change: transform;
  }
`

const Partners = () => {
  const [visibleId, setVisibleId] = useState(0)
  const partners = useLogos()
  const maxLength = partners.length

  useEffect(() => {
    const interval = setInterval(() => next(), 8000)
    return function cleanup() {
      clearInterval(interval)
    }
  })

  const prev = () => {
    if (visibleId - 1 < 0) {
      setVisibleId(maxLength - 1)
    } else {
      setVisibleId(visibleId - 1)
    }
  }
  const next = () => {
    if (visibleId + 1 >= maxLength) {
      setVisibleId(0)
    } else {
      setVisibleId(visibleId + 1)
    }
  }
  const goTo = id => () => setVisibleId(id)
  const handlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    trackTouch: true,
    trackMouse: true,
  })
  return (
    <Section>
      <Container style={{ cursor: "pointer" }} {...handlers}>
        <SectionTitle className="wow animate__fadeIn">
          Who we have worked with
        </SectionTitle>
        <SliderContainer>
          {partners.map((partner, idx) => (
            <Slide key={idx} position={visibleId * -100}>
              {partner.map((image, idx) => (
                <Image
                  image={getImage(image.src)}
                  key={idx}
                  alt={image.alt}
                  title={image.alt}
                />
              ))}
            </Slide>
          ))}
        </SliderContainer>
        <Pagination className="wow animate__fadeIn">
          <IconContainer onClick={prev}>
            <LeftArrow />
          </IconContainer>
          {partners.map((r, idx) => (
            <IconContainer
              key={idx}
              onClick={goTo(idx)}
              active={idx === visibleId}
            >
              <StyledEmptyTriangle />
            </IconContainer>
          ))}
          <IconContainer onClick={next}>
            <RightArrow />
          </IconContainer>
        </Pagination>
      </Container>
    </Section>
  )
}

export default Partners
