import { Link } from "gatsby"
import React from "react"
import styled, { keyframes } from "styled-components/macro"
import { pxToRem } from "../../../utils"
import Container from "../../common/Container"

const shake = keyframes`
10%, 90% {
  transform: translate3d(-1px, 0, 0);
}
20%, 80% {
  transform: translate3d(2px, 0, 0);
}

30%, 50%, 70% {
  transform: translate3d(-2px, 0, 0);
}
40%, 60% {
  transform: translate3d(2px, 0, 0);
`

const GradientWrapper = styled.section`
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: ${pxToRem(20)} 0;
  background: linear-gradient(
    270deg,
    rgba(91, 64, 48, 1) 0%,
    rgba(184, 136, 71, 1) 20.39%,
    rgba(255, 208, 114, 1) 49.35%,
    rgba(255, 230, 152, 1) 69.83%,
    rgba(184, 136, 71, 1) 81.1%,
    rgba(91, 64, 48, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5B4030',endColorstr='#5B4030' , GradientType=1);

  @media all and (min-width: 1025px) {
    ${props => (props.skew ? `transform: skew(0, -3deg);` : ``)};
    height: 120px;
    padding: 0;
  }
`
const GradientContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media all and (min-width: 1025px) {
    flex-direction: row;
  }
`
const Text = styled.h2`
  font-size: ${pxToRem(62)};
  font-family: "Teko", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 0.9;
  margin: 0;
  margin-bottom: ${pxToRem(15)};
  color: #fff;
  text-align: center;
  text-shadow: rgb(0 0 0 / 70%) 8px 5px 25px;
  ${props => (props.skew ? `font-style: italic;` : ``)};

  @media all and (min-width: 1025px) {
    font-size: 3.3rem;
    font-family: "Teko", sans-serif;
    font-weight: 400;
    padding-top: 10px;
    text-align: left;
    line-height: initial;
    margin-bottom: 0;
  }
`
const Button = styled(Link)`
  color: #fff;
  font-size: ${pxToRem(20)};
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  border: 2px solid #fff;
  padding: ${pxToRem(10)} ${pxToRem(35)};
  ${props => (props.skew ? `font-style: italic;` : ``)};
  border-radius: 50px;

  &:hover {
    animation: ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
    text-decoration: none;
  }

  @media all and (min-width: 375px) {
    font-size: ${pxToRem(27)};
  }

  @media all and (min-width: 1025px) {
    font-size: 1.7rem;
  }
`

const GradientCTA = ({ text, to, buttonText, skew, ...rest }) => {
  return (
    <GradientWrapper skew={skew}>
      <GradientContainer {...rest}>
        <Text skew={skew} className="wow animate__fadeIn">
          {text}
        </Text>
        <Button
          skew={skew}
          to={to}
          className="wow animate__fadeIn"
          data-wow-delay=".2s"
        >
          {buttonText}
        </Button>
      </GradientContainer>
    </GradientWrapper>
  )
}

export default GradientCTA
