import { useStaticQuery, graphql } from "gatsby"

export const useLogos = () => {
  const images = useStaticQuery(
    graphql`
      query Logos {
        allFile(
          filter: {absolutePath: {regex: "/logok/"}}
          sort: {order: ASC, fields: name}
        ) {
          edges {
            node {
              name
              src: childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    `
  )

  return (
    images.allFile.edges
      .map(image => ({
        src: image.node.src.gatsbyImageData,
        alt: image.node.name.split("__")[1].split(".")[0],
      }))
      // .sort((a, b) =>
      //   a.originalName.localeCompare(b.originalName, undefined, {
      //     numeric: true,
      //     sensitivity: "base",
      //   })
      // )
      .reduce((acc, i, idx) => {
        return !(idx % 6)
          ? [...acc, [i]]
          : [...acc, [...acc[acc.length - 1], i]]
      }, [])
      .filter(logos => logos.length === 6)
  )
}
