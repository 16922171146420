import React from "react"
import styled from "styled-components/macro"
import { StaticImage } from "gatsby-plugin-image"
import Container from "../../common/Container"
import { Link } from "gatsby"
import { pxToRem } from "../../../utils"

const ActsWrapper = styled.section`
  background-color: #000;
  padding: ${pxToRem(75)} 0 0 0;
  text-align: center;

  @media all and (min-width: 768px) {
    padding: ${pxToRem(100)} 0 0 0;
  }
`
const ActsTitle = styled.h2`
  color: #fff;
  font-weight: 600;
  font-size: ${pxToRem(40)};
  font-family: "Teko", sans-serif;
  line-height: 1.3;
  margin-top: 0;
  margin-bottom: ${pxToRem(50)};
  text-transform: uppercase;

  @media all and (min-width: 768px) {
    margin-bottom: ${pxToRem(100)};
  }
`
const ShowGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${pxToRem(40)};

  @media all and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`
const Show = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const ShowTitle = styled.h3`
  color: #fff;
  font-weight: 700;
  font-size: ${pxToRem(30)};
  line-height: 1.3;
  margin-top: 0;
  margin-bottom: ${pxToRem(40)};
  text-transform: uppercase;
`
const TailorMadeTitle = styled(ShowTitle)`
  color: #c4ff45;
`

const Acts = () => {
  return (
    <ActsWrapper>
      <Container>
        <ActsTitle className="wow animate__fadeIn">Our popular acts</ActsTitle>
      </Container>
      <ShowGrid>
        <Show
          to="/airtrack-show"
          className="wow animate__fadeInLeft"
          data-wow-delay=".2s"
        >
          <ShowTitle>Flip Unit Airtrack Show</ShowTitle>
          <StaticImage
            src="../../../images/popular-acts__flip.jpeg"
            alt="Flip Unit Airtrack Show"
          />
        </Show>
        <Show
          to="/tailor-made-show"
          className="wow animate__fadeInRight"
          data-wow-delay=".2s"
        >
          <TailorMadeTitle>Tailor-made Show</TailorMadeTitle>
          <StaticImage
            src="../../../images/popular-acts__yettel.jpeg"
            alt="Tailor-made Show"
          />
        </Show>
      </ShowGrid>
    </ActsWrapper>
  )
}

export default Acts
