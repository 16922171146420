import React from "react"
import styled from "styled-components/macro"
import { pxToRem } from "../../../utils"
import Container from "../../common/Container"

const AboutWrapper = styled.section`
  background-color: #000;
  padding: ${pxToRem(75)} 0 ${pxToRem(50)} 0;
  text-align: center;

  @media all and (min-width: 768px) {
    padding: ${pxToRem(150)} 0 ${pxToRem(100)} 0;
  }
`
const AboutTitle = styled.h2`
  font-weight: 900;
  font-family: "Teko", sans-serif;
  font-size: ${pxToRem(80)};
  margin-top: 0;
  margin-bottom: ${pxToRem(50)};
  line-height: 1;
  color: #fff;

  @media all and (min-width: 768px) {
    font-size: ${pxToRem(120)};
  }
`
const AboutTitleGradient = styled.span`
  background: linear-gradient(
    90deg,
    rgba(0, 173, 238, 1) 0%,
    rgba(46, 49, 145, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ADEE',endColorstr='#2E3191' , GradientType=1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
const AboutParagraph = styled.p`
  color: #fff;
  font-size: ${pxToRem(24)};
  font-weight: 300;
  line-height: 1.5;
`
const Video = styled.iframe`
  width: 100%;
  display: block;
  border: none;
  height: 510px;

  @media all and (min-width: 768px) {
    height: 82vh;
  }
`

const About = () => {
  return (
    <>
      <AboutWrapper>
        <Container>
          <AboutTitle className="wow animate__fadeIn">
            WHO <AboutTitleGradient>WE </AboutTitleGradient>ARE?
          </AboutTitle>
          <AboutParagraph className="wow animate__fadeIn" data-wow-delay=".2s">
            FLIP UNIT Production is an extreme sports show agency providing the
            most amusing sport-related performances, specializing in airtrack
            shows and acrobatics, and creating tailor-made shows that involve a
            variety of extreme sports.
          </AboutParagraph>
          <AboutParagraph className="wow animate__fadeIn" data-wow-delay=".3s">
            What started as a freestyle acrobat team, known as the FLIP UNIT, is
            now one of the best-known freestyle acrobat teams in Europe. After
            years of hard work we are now crossing borders and traveling the
            world with our entertaining extreme shows.
          </AboutParagraph>
          <AboutParagraph className="wow animate__fadeIn" data-wow-delay=".4s">
            As an agency we work with more than 120 professional athletes. We
            can provide you with fully tailor-made shows in any concept with pro
            dancers; acrobats; breakdancers; BMX riders, circus artists; pro TV
            choreographer; styling-costume implementing etc. to perfectly fit
            your event.
          </AboutParagraph>
          <AboutParagraph className="wow animate__fadeIn" data-wow-delay=".5s">
            We guarantee a fully developed infrastructure to make world-class
            entertaining shows to be the highlight of any prestige event!
          </AboutParagraph>
        </Container>
      </AboutWrapper>
      <Video
        loading="lazy"
        className="lazyload"
        data-src="https://www.youtube-nocookie.com/embed/kz3ZyUM_a2A"
        src={`https://www.youtube-nocookie.com/embed/kz3ZyUM_a2A`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        allowFullScreen
      />
    </>
  )
}

export default About
