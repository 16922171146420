import { useStaticQuery, graphql } from "gatsby"

export const useHeroAssets = () => {
  const images = useStaticQuery(
    graphql`
      query HeroImages {
        otherBW: file(relativePath: { eq: "other_bw_inv.jpeg" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [JPG])
          }
        }
        tailorBW: file(relativePath: { eq: "tailor-made_bw.jpg" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [JPG])
          }
        }
        flipunitBW: file(relativePath: { eq: "flipunit_bw.jpg" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [JPG])
          }
        }
        other: file(relativePath: { eq: "other_inv.jpeg" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [JPG])
          }
        }
        tailor: file(relativePath: { eq: "tailor-made.jpg" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [JPG])
          }
        }
        flipunit: file(relativePath: { eq: "flipunit.jpg" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [JPG])
          }
        }
        triangleBg: file(relativePath: { eq: "Asset 16@2x.jpg" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [JPG])
          }
        }
      }
    `
  )

  return {
    other: images.other.src.gatsbyImageData.images.fallback.src,
    tailor: images.tailor.src.gatsbyImageData.images.fallback.src,
    flipunit: images.flipunit.src.gatsbyImageData.images.fallback.src,
    otherBW: images.otherBW.src.gatsbyImageData.images.fallback.src,
    tailorBW: images.tailorBW.src.gatsbyImageData.images.fallback.src,
    flipunitBW: images.flipunitBW.src.gatsbyImageData.images.fallback.src,
    triangleBg: images.triangleBg.src.gatsbyImageData.images.fallback.src,
  }
}
